/**
* Generated automatically at built-time (2024-11-05T09:52:58.994Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "udekokken-inspiration",templateKey: "sites/103-c1f5e199-acbe-4ab8-8a83-7815b7301c09"};